body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #efece3 !important;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jumbotron {
    background-color:#efece3 !important; 
    background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, #efece3));
    /* Opera 11.10+ */
    background: -o-linear-gradient(top, white, #efece3);
    /* Firefox 3.6+ */
    background: -moz-linear-gradient(top, white, #efece3);
    /* Newer Browsers */
    background: linear-gradient(to bottom, white, #efece3);
}

.main-body {
  padding-top: 42px;
}
