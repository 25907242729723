.round-img {
    height: 120px !important;
    width: 120px !important;
    padding: 5px;
    margin-top: -25px;
    border-radius: 50% !important;

}
.card-title {
    font-size: x-large;
    color: #C71585;
    text-align: center;
    font-weight: bolder;
}
.card-link a {
    font-size: medium;
    color: #C71585 !important;
    text-align: center;
    font-weight: bolder;
}
.entry-cards {
    display: flex;
    flex-wrap: wrap;
}
.entry-card {
/*    min-width: 150px !important;
    width: 225px !important;
    margin: 10px;
    background-color: #efece3 !important;
    flex: 1 0 500px;
    align-items: center;
    height: 300px; */
    margin: 1rem .25em;
    box-sizing: border-box;
    margin: 5px !important;
    padding: 0px !important;
}
.entry-card-selected {
    /* background-color: gold !important;*/
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #e6c37d 8%, #c5ab73 30%, #EDD192 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #ebd495 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}
.entry-button {
    /* background-color: gold !important;*/
    background-color: #C71585 !important;
    color: white;
    border-width: 2px;
    border-color: goldenrod;
}

.card-footer {
    border: none;
    background-color: rgb(0, 0, 0, 0) !important;
}
.card-subtitle {
    font-style: italic;
    font-weight: lighter;
    font-size: small;
    max-width: 225px;
}
.bullet {
    display: 'inline-block';
    margin: '0 2px';
    transform: 'scale(0.8)';
  }
 title {
    font-size: 14;
 }
 pos {
    margin-bottom: 12;
 }
@media screen and (min-width: 0em) {
    .entry-card {
       min-width: calc(50% -  2em);
    }
    .card-title {
        font-size: medium;
    }
    .card-body {
       font-size: smaller; 
    }
}

@media screen and (min-width: 30em) {
    .entry-card {
       min-width: calc(33% -  1em);
    }
    .card-title {
        font-size: large;
    }
    .card-body {
       font-size: small; 
    }
}

@media screen and (min-width: 60em) {
    .entry-card {
        min-width: calc(20% - 1em);
    }
    .card-title {
        font-size: larger;
    }
    .card-body {
       font-size: small; 
    }
}

.centered {
    margin: 0 auto;
    padding: 0 1em;
}



