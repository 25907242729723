.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #C71585;
}

a {
  color: #000000 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.card-img {
  overflow: hidden;
}

.panel__head {
	padding: 0;
	border: 0;
	cursor: pointer;
	background-color: transparent;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.panel__body {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	transition: height 0.35s ease-in-out;
}

.panel__content {
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
}

.panel[aria-expanded='true'] .panel__content {
	opacity: 1;
}
.jumbotron {
  padding: 0px !important;
}
